import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
  CompetenceAnalyticsFilters,
  CompetenceDropdown,
} from '@/widgets';

import { ImportTestsPopup } from '@/features/projects';

import { getEmployees } from '@/features/employee';
import { getExternalTests } from '@/features/externalTest';
import { getCompany } from '@/entities/company/api';
import { getCompanyGroups } from '@/features/department';

import competenceAnalyticsState from './store/competenceAnalyticsState';

import {
  InputGroup,
  InputLeftElement,
  Input,
  Button,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useDisclosure,
} from '@chakra-ui/react';

import {
  TopMenu,
  TestResultsTable,
  TableBody,
  TableHeader,
  TableRow,
  TableHeaderElement,
  TableRowElement,
  GroupCompetenceAnalytics,
  EmployeeCompetenceAnalytics,
  EmptyBlock,
  HeadInfo,
  HeadInfoItem,
  HeadInfoItemDot,
  NoData,
} from './styles';
import { ArrowOpen } from '@/UI';

export const CompetenceAnalytics: React.FC = observer(() => {
  const { isOpen: importIsOpen, onOpen: importOnOpen, onClose: importOnClose } = useDisclosure();

  const [employees, setEmployees] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [groups, setGroups] = useState([]);
  const [externalTests, setExternalTests] = useState([]);

  const user = JSON.parse(
    localStorage.getItem('user')
  );
  const companyId = user?.company[
    JSON.parse(
      localStorage.getItem('user')
    )?.company.length - 1
  ];

  function handleGetEmployees() {
    getEmployees(companyId)
      .then((response: any) => {
        setEmployees(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
  }

  function handleGetExternalTests() {
    getExternalTests()
      .then((response: any) => {
        setExternalTests(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const handleOpacity = (isLoading: boolean) => {
    
  }

  const handleExpandCompetence = (competence: any) => {
    
  }

  useEffect(() => {
    handleGetEmployees();
    handleGetExternalTests();
  }, [])

  return (
    <div>
      <TopMenu>
        {externalTests.length > 0 &&
        <TestResultsTable>
          <TableHeader>
            <TableHeaderElement>
              Тест
            </TableHeaderElement>
            <TableHeaderElement>
              Баллы
            </TableHeaderElement>
            <TableHeaderElement>
              ФИО
            </TableHeaderElement>
            <TableHeaderElement>
              Компетенция
            </TableHeaderElement>
          </TableHeader>
          <TableBody>
            {externalTests.map((externalTest: any) => 
              <TableRow>
                <TableRowElement>
                  {externalTest.name}
                </TableRowElement>
                <TableRowElement>
                  {externalTest.score}
                </TableRowElement>
                <TableRowElement>
                  {externalTest.user}
                </TableRowElement>
                <TableRowElement>
                  {externalTest.competence.name}
                </TableRowElement>
              </TableRow>
            )}
            {/* <TableRow>
              <TableRowElement>
                Проверочный тест
              </TableRowElement>
              <TableRowElement>
                50
              </TableRowElement>
              <TableRowElement>
                Иван Петрович Смолов
              </TableRowElement>
              <TableRowElement>
                Компетенция А
              </TableRowElement>
            </TableRow>
            <TableRow>
              <TableRowElement>
                Проверочный тест
              </TableRowElement>
              <TableRowElement>
                90
              </TableRowElement>
              <TableRowElement>
                Смолянюк Анна Федоровна
              </TableRowElement>
              <TableRowElement>
                Компетенция А
              </TableRowElement>
            </TableRow>
            <TableRow>
              <TableRowElement>
                Проверочный тест 2
              </TableRowElement>
              <TableRowElement>
                40
              </TableRowElement>
              <TableRowElement>
                Иван Петрович Смолов
              </TableRowElement>
              <TableRowElement>
                Компетенция Б
              </TableRowElement>
            </TableRow>
            <TableRow>
              <TableRowElement>
                Проверочный тест 2
              </TableRowElement>
              <TableRowElement>
                40
              </TableRowElement>
              <TableRowElement>
                Смолянюк Анна Федоровна
              </TableRowElement>
              <TableRowElement>
                Компетенция Б
              </TableRowElement>
            </TableRow>
            <TableRow>
              <TableRowElement>
                Проверочный тест 3
              </TableRowElement>
              <TableRowElement>
                44
              </TableRowElement>
              <TableRowElement>
                Иван Петрович Смолов
              </TableRowElement>
              <TableRowElement>
                Компетенция В
              </TableRowElement>
            </TableRow>
            <TableRow>
              <TableRowElement>
                Проверочный тест 3
              </TableRowElement>
              <TableRowElement>
                25
              </TableRowElement>
              <TableRowElement>
                Смолянюк Анна Федоровна
              </TableRowElement>
              <TableRowElement>
                Компетенция В
              </TableRowElement>
            </TableRow> */}
          </TableBody>
        </TestResultsTable>
        }

        <Button
          borderRadius='8px'
          bg='var(--main-purple)'
          _hover={{ bg: 'var(--hover-purple)' }}
          onClick={importOnOpen}
        >
          <h6 style={{ color: '#fff' }}>Импортировать тесты</h6>
        </Button>
      </TopMenu>
      <CompetenceAnalyticsFilters
        employees={employees}
        departments={departments}
        groups={groups}
      />
      {competenceAnalyticsState.filter === 'all' ? 
        <GroupCompetenceAnalytics>
          <EmptyBlock>
          </EmptyBlock>
          <HeadInfo>
            <HeadInfoItem>
              <HeadInfoItemDot 
                style={{backgroundColor: '#E0E3EF'}}
              />
              <div>
                Слабый
              </div>
            </HeadInfoItem>
            <HeadInfoItem>
              <HeadInfoItemDot 
                style={{backgroundColor: '#B7C6FF'}}
              />
              <div>
                Уверенный
              </div>
            </HeadInfoItem>
            <HeadInfoItem>
              <HeadInfoItemDot 
                style={{backgroundColor: '#6B8BFF'}}
              />
              <div>
                Сильный
              </div>
            </HeadInfoItem>
          </HeadInfo>
          {competenceAnalyticsState.current.competences.length === 0 ?
            <NoData>
              Нет данных
            </NoData>
          :
            competenceAnalyticsState.current.competences.map((competence: any) => 
              <CompetenceDropdown
                key={competence.name}
                competence={competence}
                isGroup={true}
              />
            )
          }
        </GroupCompetenceAnalytics>
      : 
        <EmployeeCompetenceAnalytics>
          <EmptyBlock>
          </EmptyBlock>
          <HeadInfo>
            <HeadInfoItem>
              <HeadInfoItemDot 
                style={{backgroundColor: '#E0E3EF'}}
              />
              <div>
                Слабый
              </div>
            </HeadInfoItem>
            <HeadInfoItem>
              <HeadInfoItemDot 
                style={{backgroundColor: '#B7C6FF'}}
              />
              <div>
                Уверенный
              </div>
            </HeadInfoItem>
            <HeadInfoItem>
              <HeadInfoItemDot 
                style={{backgroundColor: '#6B8BFF'}}
              />
              <div>
                Сильный
              </div>
            </HeadInfoItem>
          </HeadInfo>
          {competenceAnalyticsState.current.competences.length === 0 ?
            <NoData>
              Нет данных
            </NoData>
          :
            competenceAnalyticsState.current.competences.map((competence: any) => 
              <CompetenceDropdown
                key={competence.name}
                competence={competence}
                isGroup={false}
              />
            )
          }
        </EmployeeCompetenceAnalytics>
      }
      <ImportTestsPopup isOpen={importIsOpen} onClose={importOnClose} />
    </div>
  )
});
