import styled from "styled-components";

export const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, 296px);
    gap: 16px;

    @media screen and (max-width: 1268px) {
        grid-template-columns: repeat(3, 296px);
    }
`;