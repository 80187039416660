import styled from "styled-components";

export const CompetenceRow = styled.div`
  box-sizing: border-box;
  grid-column: 1 / 3;
  display: grid;
  grid-template-columns: 2fr 9fr;
  box-shadow: 0 1px 0 0 #E6E6E6;
`;

export const IndicatorRow = styled.div`
  position: relative;
  box-sizing: border-box;
  grid-column: 1 / 3;
  display: grid;
  grid-template-columns: 2fr 9fr;
  transition: .3s;
`;

export const CompetenceHeader = styled.div`
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  height: 50px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: right;
  border-right: 1px solid #E6E6E6;
  cursor: pointer;
`;

export const ArrowContainer = styled.div`
  transition: .3s;
`;

export const CompetenceExpand = styled.div`
  
`;

export const CompetenceInfo = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  text-align: right;
`;

export const IndicatorHeader = styled.div`
  box-sizing: border-box;
  height: 100%;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  border-right: 1px solid #E6E6E6;
`;

export const IndicatorName = styled.div`
  font-size: 12px;
`;

export const CompetenceName = styled.div`
  font-weight: bold;
  font-size: 13px;
`;

export const CompetenceLevelName = styled.div`
  font-size: 11px;
`;

export const LevelGradation = styled.div`
  box-sizing: border-box;
  height: 50px;
  display: flex;
  flex-shrink: 1;
  border-bottom: 1px solid #E6E6E6;
`;

export const LevelPercentage = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%
`;
