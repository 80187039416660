import styled from "styled-components";

export const TopMenu = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 0 50px 0;
`;

export const TestResultsTable = styled.div`
  width: 900px;
  display: flex;
  flex-direction: column;
  margin: 0;
`;

export const TableBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 276px;
  overflow: auto;
`;

export const TableHeader = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`;

export const TableRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`;

export const TableHeaderElement = styled.div`
  color: #909090;
  padding: 12px 12px 12px 0;
  border-bottom: 1px solid #E6E6E6;
`;

export const TableRowElement = styled.div`
  padding: 12px 12px 12px 0;
  border-bottom: 1px solid #E6E6E6;
`;

export const GroupCompetenceAnalytics = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 2fr 9fr;
  width: 100%;
  border: 1px solid #E6E6E6;
  border-radius: 16px;
  overflow: hidden;
  margin: 0 0 50px 0;
`;

export const EmptyBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid #E6E6E6;
  border-right: 1px solid #E6E6E6;
  padding: 12px;
  text-align: right;
  font-size: 12px;
`;

export const HeadInfo = styled.div`
  box-sizing: border-box;
  display: flex;
  height: 50px;
  gap: 20px;
  padding: 12px;
  border-bottom: 1px solid #E6E6E6;
`;

export const HeadInfoItem = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
`;

export const HeadInfoItemDot = styled.div`
  box-sizing: border-box;
  width: 7px;
  height: 7px;
  border-radius: 10px;
`;

export const EmployeeCompetenceAnalytics = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 2fr 9fr;
  width: 100%;
  border: 1px solid #E6E6E6;
  border-radius: 16px;
  overflow: hidden;
  margin: 0 0 50px 0;
`;

export const NoData = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  padding: 12px;
  color: #aaaaaa;
  font-size: 12px;
`;