import styled from "styled-components";

export const Head = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const H6 = styled.h6`
    color: var(--main-purple);
`;

export const Competences = styled.div`
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
`;

export const Skills = styled.div`
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
`;

export const EmptyBlock = styled.div`
    color: gray;
    padding: 2px 0;
`;

export const Competence = styled.div`
    
`;

export const Skill = styled.button`
    display: flex;
    align-items: center;
    width: max-content;
    word-break: break-all;
    gap: 6px;
    min-height: 24px;
    padding: 2px 8px;
    border-radius: 4px;
    color: #ffffff;
`;

export const Info = styled.div`
    width: 100%;
    display: flex;
    gap: 16px;
`;

export const InfoSection = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const InfoDot = styled.div`
    width: 7px;
    height: 7px;
    border-radius: 10px;
`;
