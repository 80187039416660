import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navbar } from '@/widgets';
import { Outlet } from 'react-router-dom';
import { Page, Board } from './styles';

const categories = [
  {
      id: 1,
      title: 'Личное',
      links: [
          {
              id: 1,
              title: 'Опыт и навыки',
              to: '/dashboard-manager-profile',
          },
          {
              id: 2,
              title: 'Проекты и задачи',
              to: '/dashboard-manager-tasks',
          },
          {
            id: 3,
            title: 'Моя карьера',
            to: '/dashboard-manager-career',
          },
          {
              id: 4,
              title: 'Курсы',
              to: '/dashboard-manager-courses',
          },
          {
              id: 5,
              title: 'Тесты',
              to: '/dashboard-manager-tests',
          },
          {
            id: 6,
            title: 'Аналитика',
            to: '/dashboard-manager-analitic',
          },
      ],
  },
  {
      id: 2,
      title: 'Другое',
      links: [
          {
              id: 6,
              title: 'Ярмарка проектов',
              to: '/dashboard-manager-projects',
          },
          {
            id: 7,
            title: 'Сотрудники',
            to: '/dashboard-manager-employees',
        },
      ],
  },
  {
    id: 3,
    title: 'Новое',
    links: [
        {
            id: 5,
            title: 'Аналитика компетенций',
            to: '/dashboard-manager-competence-analytics'
        },
    ]
}
];

export const ManagerLayout: React.FC = () => {
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
      if (!user) {
        navigate('/');
      }
  
      if (user?.role !== 'Manager') {
        navigate('/');
      }
  }, [])

  return (
    <Page>
      <Navbar categories={categories} />
      <Board>
          <Outlet />
      </Board>
    </Page>
  )
}
