import React, { useState } from 'react';
import { ICompetence } from '@/shared';
import {
    Container,
    Top,
    Indicators,
    Indicator,
    Line,
    IndicatorDot,
    IndicatorBody,
    IndicatorHeader,
    IndicatorTest,
    // SkillsWrapper,
    // SkillsTitle,
} from './styles';
import DotsIcon from '@/assets/icons/dots.svg';
import CollapseIcon from '@/assets/icons/collapse.svg';
import { SkillsList } from '@/widgets';
import { observer } from 'mobx-react-lite';
import competenceState from '../store/competencesState';
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
} from '@chakra-ui/react';
import { EditCompetencePopup } from '@/features/competences';
import { useDisclosure } from '@chakra-ui/react';
import toast, { Toaster } from 'react-hot-toast';
import { deleteCompetence } from '@/features/competences';

export interface CompetenceCardProps {
    competence: ICompetence;
}

export const CompetenceCard: React.FC<CompetenceCardProps> = observer(({ competence }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const companyId = JSON.parse(localStorage.getItem('user')).company[JSON.parse(localStorage.getItem('user')).company.length - 1];


  function handleDeleteCompetence() {
    deleteCompetence(competence.id)
      .then((response) => {
        const deletedCompetenceId: number = competence.id;
        competenceState.setCompetences(
          competenceState.competences.filter(
            (competence: ICompetence) => {
              return competence.id !== deletedCompetenceId;
            }
          )
        )
      })
  }

  return (
    <>
      <Container>
        <Top>
          <h5>{competence.name}</h5>
          <Popover placement='bottom-end'>
            <PopoverTrigger>
              <button>
                  <DotsIcon color='var(--main-purple)'/>
              </button>
            </PopoverTrigger>
            <PopoverContent width={'200px'}>
              <PopoverBody>
                <button
                  style={{ fontSize: '12px' }}
                  onClick={onOpen}
                >
                  Редактировать компетенцию
                </button>
                <button
                  style={{
                      fontSize: '12px',
                      color: 'var(--addable-red)'
                  }}
                  onClick={handleDeleteCompetence}
                >
                  Удалить из базы
                </button>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Top>
        <Indicators>
          <Line/>
          {competence.indicators.map((indicator: any) => 
            <Indicator key={indicator.name}>
              <IndicatorDot/>
              <IndicatorBody>
                <IndicatorHeader>
                  {indicator.name}
                </IndicatorHeader>
                <IndicatorTest>
                  {indicator.test}
                </IndicatorTest>
              </IndicatorBody>
            </Indicator>
          )}
        </Indicators>
      </Container>

      <EditCompetencePopup isOpen={isOpen} onClose={onClose} competence={competence}/>
      <Toaster position="top-right" />
    </>
  )
});
