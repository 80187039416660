import React, { useState, useEffect } from 'react';
import { Card, ICompetence, IUser } from '@/shared';
import {
    Head,
    H6,
    Competences,
    Competence,
    Skills,
    Skill,
    Info,
    InfoSection,
    InfoDot,
    EmptyBlock,
} from './styles';
import { Filters } from '@/features/common';
import EditIcon from '@/assets/icons/edit.svg';
import { Button, useDisclosure } from '@chakra-ui/react';
// import { Skill } from '@/shared';
import { EditSkillsPopup } from '@/features/employee';
import { getSkills, toggleSkillVerified } from '@/features/employee';
import { styled, createGlobalStyle } from 'styled-components';

export interface MyCompetencesProps {
    user: IUser;
}

const GlobalStyle = createGlobalStyle`
    .custom-skill-container > div {
        background: none !important;
        border: none !important;
    }
`;

export const MyCompetences: React.FC<MyCompetencesProps> = ({ user }) => {
    const current_user = JSON.parse(localStorage.getItem('user'));

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [skillsType, setSkillsType] = useState('Hard');
    const [skillsOfType, setSkillsOfType] = useState(false);
    const [skills, setSkills] = useState([]);

    useEffect(() => {
        getSkills(user?.id)
            .then((res: any) => {
                if (res?.status === 200) {
                    setSkills(res.data.skill);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }, [user?.id]);

    useEffect(() => {
        let flag = false;
        if (skills) {
            for (const skill of skills) {
                if (skill.type === skillsType) {
                    flag = true;
                    break;
                }
            }
        }
        setSkillsOfType(flag);
    }, [skillsType, skills]);

    function handleToggleVerifySkill(skill: any) {
        if (current_user.role !== 'User') {
            toggleSkillVerified(user.id, skill.id, !skill.verified)
                .then((response: any) => {
                    setSkills(skills.map(s =>
                        s.id === skill.id ? { ...s, verified: !skill.verified } : s
                    ));
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }

    return (
      <>
        <GlobalStyle />
          <Card>
            <Head>
              <h3>Компетенции</h3>
              <Button
                onClick={onOpen}
                border='1px var(--addable-gray) solid'
                borderRadius='8px'
                bg='var(--main-white)'
                leftIcon={<EditIcon color='var(--main-purple)' />}
              >
                <H6>Редактировать</H6>
              </Button>
            </Head>

            {user.competences ? 
              <Competences>
                {user.competences && user.competences.map((competence: ICompetence) => {
                  <Competence
                    key={competence.id}
                  >

                  </Competence>
                })}
              </Competences>
              :
                <EmptyBlock>
                  Не найдены
                </EmptyBlock>
            }
            
            {/* {skillsOfType ? 
              <Skills>
                {skills && skills.map((skill: any) => (
                  <div>
                  {skill.type === skillsType &&
                    <Skill
                      key={skill.id}
                      style={{
                          backgroundColor: skill.verified ? 'var(--main-purple)' : 'gray',
                          cursor: current_user.role === 'User' ? 'default' : 'pointer',
                      }}
                      onClick={() => handleToggleVerifySkill(skill)}
                    >
                      {skill.name}
                    </Skill>    
                  }
                  </div>
                ))}
              </Skills>
            :
              <EmptyBlock>
                Не найдены
              </EmptyBlock>
            } */}

          </Card>
        <EditSkillsPopup user={user} isOpen={isOpen} onClose={onClose}/>
      </>
    )
}
